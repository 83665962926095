import { Header } from "../components/Header";
import React from 'react';
import { Main, HeadText } from "../components/Main";

const Logo = () => {
  return (
    <div className="col-span-2 iphone:col-span-1">
      <img
        alt="logo"
        src={process.env.PUBLIC_URL + "LOGO_heidemariejauk.svg"}
        className="w-44 iphone:w-24"></img>
    </div>
  );
};

export const Landing = () => {
  return (
    <div className="px-40 grid grid-cols-12 tk-freight-text-pro gap-5 gap-y-28 iphone:grid-cols-4 iphone:gap-2.5 iphone:gap-y-20 iphone:px-5 iphone:py-8 overflow-hidden">
      <Logo />
      <Header />
      <div className="hidden iphone:grid iphone:col-span-4">
        <HeadText />
      </div>
      <div className="col-start-3 col-span-3 iphone:col-start-1 iphone:col-span-4 relative">
        <img
          src={process.env.PUBLIC_URL + "Foto_HeidemarieJauk.png"}
          className="relative w-96"
          alt="Heidemarie Jauk"/>
        <img
          src={process.env.PUBLIC_URL + "deko.svg"}
          className="absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 "
        />
      </div>
      <Main />
    </div>
  );
};
