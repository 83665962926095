import React from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga";
import { useEffect } from 'react';
import { Landing } from './pages/Landing';




function App() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="iphone:h-auto pt-16  text-white iphone:p-0">
      <Helmet>
        <title>Fastenwochen - Heidemarie Jauk</title>
        <meta name="description" content="Heidemarie Jauk bietet ganzheitliche Entspannung bei Fastenwochen in den Weinbergen der Südsteiermark. Genießen Sie die Entschleunigung in der idyllischen Weinberglandschaft am Weingut Skringer." />
        <meta name="keywords" content="Fastenwochen, Basenfasten, Heidemarie Jauk, Großklein, Styria, Steiermark, Österreich, Weinberge, Weingut Skringer, Entspannung" />
        <meta property="og:title" content="Fastenwochen - Heidemarie Jauk" />
        <meta property="og:description" content="Heidemarie Jauk bietet ganzheitliche Entspannung bei Fastenwochen in den Weinbergen der Südsteiermark. Genießen Sie die Entschleunigung in der idyllischen Weinberglandschaft am Weingut Skringer." />
        <meta property="og:image" content="https://fastenwochen.com/images/fastenwochen.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fastenwochen.com/" />
        <meta property="og:locale" content="de_AT" />
        

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-7523SSLFBE"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          
          function gtag(){
            dataLayer.push(arguments);
          }
          gtag('js', new Date());

          gtag('config', 'G-7523SSLFBE');
          </script> */}

      </Helmet>
      <Landing />
    </div>
  );
}

export default App;
