import React from 'react';

export const Header = () => {
  return (
    <div className="col-span-10 iphone:col-span-3 iphone:pl-10 my-auto">
      <div className="flex flex-row items-center">
        <div className="w-full flex flex-col gap-2">
          <p className="tk-brother-1816 text-xl iphone:text-xs">
            Meine Website ist noch im Aufbau.
          </p>
          <p className="tk-freight-text-pro-italic text-3xl  iphone:text-xl">
            Bis dahin... Abwarten und Tee trinken.
          </p>
        </div>
      </div>
    </div>
  );
};
