import React from 'react';

export const HeadText = () => {
  return (
    <div className='cursiv-font text-5xl leading-tight tk-freight-text-pro-italic iphone:text-6xl iphone:leading-snug col-span-4'>
      Gönne dir eine ganzheitliche Auszeit für Körper, Geist & Seele.
    </div>
  );
};

export const Main = () => {
  const sendMail = () => {
    // Fixme: implement REACTGA

    const mailto = 'mailto:heidemarie.jauk@fastenwochen.com?subject=Fastenwoche';
    window.location.href = mailto;
  };

  return (
    <div className='col-start-7 col-span-5 iphone:col-span-4'>
      <div className='grid grid-cols-4 gap-y-2'>
        <div className='grid col-span-4 iphone:hidden'>
          <HeadText />
        </div>
        <p className='tk-freight-text-pro text-2xl mt-32 iphone:-mt-0 col-span-4'>
          Basenfasten*
        </p>
        <p className='tk-brother-1816 text-xl col-span-4'>13.02 - 18.02.2023</p>
        <div className='col-span-2 '>
          <a
            href='https://www.skringer.at/'
            className='tk-brother-1816 text-xl'>
            Weingut Skringer
          </a>
        </div>
        <div className='iphone:col-span-4 iphone:mt-3.5 col-span-2'>
          <button
            className='col-span-2 border border-1 text-xl tk-freight-text-pro px-16 py-1'
            onClick={sendMail}>
            Jetzt anmelden
          </button>
        </div>
        <hr className='mt-3.5 iphone:hidden' />
        <p className='col-start-3 col-span-2 cursiv-font iphone:col-start-1 iphone:col-span-4 iphone:text-lg'>
          *nur noch wenige Plätze verfügbar!
        </p>
        <p className='col-span-4 mt-3.5 iphone:text-lg iphone:py-10'>
          Infos und Termine zu den nächsten Fastenwochen auf Anfrage unter {" "}
          <a href={'mailto: heidemarie.jauk@fastenwochen.com'}>
            heidemarie.jauk@fastenwochen.com
          </a>
        </p>
      </div>
    </div>
  );
};
